import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as jobNavStyles from "./jobsNavChefs.module.css"
import { Navbar, Nav } from "react-bootstrap"
import { SquareFill } from "react-bootstrap-icons"

const JobsNavFohm = ({ link }) => {
  return (
    <>
      <div>
        <Navbar expand="lg" className="justify-content-center">
          <Nav fill className="mx-auto parent">
            <Link to="/restaurant-management">
              <div
                className={
                  link === "restaurant-management"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                RESTAURANT, CAFÉ & RETAIL MANAGEMENT
              </div>
            </Link>
            <Link to="/hotel-management">
              <div
                className={
                  link === "hotel-management"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                HOTEL /F&B MANAGEMENT
              </div>
            </Link>
            <Link to="/bar-management">
              <div
                className={
                  link === "bar-management"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                BAR MANAGEMENT
              </div>
            </Link>
            <Link to="/general-management">
              <div
                className={
                  link === "general-management"
                    ? jobNavStyles.linkTextSelected
                    : jobNavStyles.linkText
                }
              >
                ASSISTANT GENERAL MANAGER
              </div>
            </Link>
          </Nav>
        </Navbar>
      </div>
    </>
  )
}

JobsNavFohm.propTypes = {
  siteTitle: PropTypes.string,
}

JobsNavFohm.defaultProps = {
  siteTitle: ``,
}

export default JobsNavFohm
